import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const GreenLink = tw.a`text-primary-500 hocus:text-primary-400`
const TextColumn = styled(Column)(props => [
  tw`md:w-6/12 mt-8 md:mt-0`,
  props.textOnLeft ? tw`md:mr-8 lg:mr-16 md:order-first` : tw`md:ml-8 lg:ml-16 md:order-last`
]);
const TextContent = tw.div`lg:py-8`;

const Heading = tw(SectionHeading)`text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100 mt-4`

const AboutUs = ({textOnLeft = false}) => {

  return (
    <div id="nav-about-us">
    <Container>
      <TwoColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Heading>Privacy and Cookie Policy</Heading>
            <Description>We never send out unsolicited emails, or pass on email addresses to any third parties. The only emails you receive from us will be to inform you of the status of your booking request.</Description>
            <Description>Our policy is to keep the personal information we receive completely confidential, and used solely for internal purposes, and to connect you to the ticket venue of your choice. We will not share your personal information with any other external parties outside of us and the venue of your ticket request.</Description>
            <Description>Where it is necessary to share with a venue, it will be pertinent information only. This may include any survey answers included in the request form on behalf of the venue. By requesting a ticket, you are giving us permission to do that.</Description>
            <Description>We are compliant with GDPR guidelines. If at any time you wish us to delete any data held for our email/ticket purposes, please <GreenLink href="/contact">contact us</GreenLink>.</Description>
            <Description>This website uses cookies for site navigation, no data about you is stored or passed to external parties at this time.</Description>

          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
    </div>
  );
};

export default AboutUs
