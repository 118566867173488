import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import ContactUsForm from 'components/clarebrand/ContactUsForm'

const Container = tw.div`relative`;

const Content = tw.div``;
const ContentStyled = styled(Content)(props => [
  tw` `,
  props.YSpacing ? tw`py-10 lg:py-12` : tw``
]);

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-primary-500 text-gray-100 relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl text-gray-100 content-center font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-white text-base font-medium tracking-wide border-0 border-b-2 py-2 text-white focus:border-primary-200 hover:border-primary-200 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-green-300`}
    }
  }
`;

const SimpleForm =  () => {
  return (
    <div id="nav-contact-us">
    <Container>
      <ContentStyled>
        <FormContainer>
          <div tw="mx-auto max-w-4xl">
            <h2>Contact Us</h2>
            <ContactUsForm />
          </div>
        </FormContainer>
      </ContentStyled>
    </Container>
    </div>
  );
};

export default SimpleForm;
