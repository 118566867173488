import React from "react"
import "style.css"
import "tailwindcss/lib/css/preflight.css"
import Nav from "components/clarebrand/Nav"
import Hero from "components/clarebrand/Landing"
import Services from "components/clarebrand/Services"
import Contact from "components/clarebrand/ContactUs"
import PrivacyInfo from "components/clarebrand/Privacy"
import Footer from "components/clarebrand/Footer"
import CookieConsent from "react-cookie-consent"

const ConsentButtonText = 'OK'
const ConsentText = 'This website uses cookies to enhance the user experience. '

function App() {
  switch(window.location.pathname ) {
    case '/book':
      return <ContactSingle />
    case '/services':
      return <ServiceSingle />
    case '/privacy':
      return <PrivacyPolicy />
    default:
      return <Home />
  }
}

function Home() {
  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText={ConsentButtonText}
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        {ConsentText}
      </CookieConsent>
      <Hero />
      <Services />
      <Contact />
      <Footer />

    </div>
  )
}

function PrivacyPolicy() {
  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText={ConsentButtonText}
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        {ConsentText}
      </CookieConsent>
      <Nav />
      <PrivacyInfo />
      <Footer />

    </div>
  )
}

function ContactSingle() {
  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText={ConsentButtonText}
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        {ConsentText}
      </CookieConsent>
      <Nav />
      <Contact />
      <Footer />

    </div>
  )
}

function ServiceSingle() {
  return (
    <div>
      <CookieConsent
        location="bottom"
        buttonText={ConsentButtonText}
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        {ConsentText}
      </CookieConsent>
      <Nav />
      <Services />
      <Footer />

    </div>
  )
}

export default App
