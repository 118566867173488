import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";

import defaultCardImage from "images/shield-icon.svg";

import PoundIconImage from "images/Pound_Sign.svg";
import MapIconImage from "images/Map_marker.svg";
import CalendarIconImage from "images/Calendar.svg";

const Container = tw.div`relative bg-primary-200 -mx-8 px-8 text-gray-700`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-700`;
const Heading = tw(SectionHeading)`w-full`;
const ItalicSpan = tw.span`italic`;
const Description = tw.p`text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-300 mt-4`

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-xs`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-center text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`bg-gray-100 text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`tracking-wider text-center font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-2 font-normal text-center text-gray-700 leading-snug`}
  }
`;

const OurValues =  ({
  cards = null,
  heading = "Our Values",
  subheading = ""
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    { imageSrc: PoundIconImage, title: "Pricing", description: "The hall is available for morning, afternoon and evening blocks for just £30 all inclusive. Running a regular club, or meeting, planning something bigger? Please contact us for a more favourable rate.", italicText:""},
    { imageSrc: MapIconImage , title: "Local", description: "Clarebrand Hall is less than 2 miles out past Castle Douglas High School, with a regular 503 bus service for those without their own wheels.", italicText:"" },
    { imageSrc: CalendarIconImage, title: "Booking", description: "Simply fill in our online booking form and we'll get right back to you. Make sure to tell us when! We also offer free advertising for your event via our site and locally.", italicText:"" }
  ];

  if (!cards) cards = defaultCards;

  return (
    <div id="nav-our-values">
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        <Description>Clarebrand Village Hall is available for anyone in the local community to hire for a wide range of needs. Do you run a club? Do you need a regular meeting space? Clarebrand has beautiful, calming views. Ideal for exercise classes, both in and outdoors. We have two sizable halls and a host of amenities including a full kitchen. We have parking too. Come and check this wonderful space out now.</Description>
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}<ItalicSpan>{card.italicText}</ItalicSpan>
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
    </Container>
    </div>
  );
};

export default OurValues
